<template>
  <div
    class="
      dis_flex
      flex_dir_col
      bg_col_fff
      bor_rad_4
      box_sha_0_0_8_8_black_80_per
      m_0_16
    "
  >
    <!-- 待放款统计 -->
    <div class="font_16 font_bold m_0_12 m_t_16">待放款统计</div>
    <div>
      <!-- 项 -->
      <div
        v-for="(item, index) in statisticList"
        :key="index"
        class="dis_flex ali_it_cen m_0_12 m_t_16"
      >
        <!-- 图标 -->
        <img class="w_32 dis_flex" :src="item.iconUrl" />

        <div class="w_24"></div>

        <!-- 属性 -->
        <div
          v-for="(item1, index1) in item.values"
          :key="index1"
          class="flex_1"
        >
          <div class="font_16 font_bold l_h_100_per">{{ item1.value }}</div>
          <div class="font_12 col_666 l_h_100_per m_t_8">
            {{ item1.label }}
          </div>
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 灰线 -->
    <div class="h_1 bg_col_F5F7F9 m_0_12 m_t_16"></div>

    <!-- 待放分析 -->
    <div class="font_14 font_bold l_h_100_per m_0_12 m_t_16">待放分析</div>
    <div>
      <!-- 项 -->
      <div
        v-for="(item, index) in analysisList"
        :key="index"
        class="dis_flex ali_it_cen m_0_12 m_t_16"
      >
        <!-- 图标 -->
        <img class="w_32 dis_flex" :src="item.iconUrl" />

        <div class="w_24"></div>

        <!-- 属性 -->
        <div
          v-for="(item1, index1) in item.values"
          :key="index1"
          class="flex_1"
        >
          <div class="font_16 font_bold l_h_100_per">{{ item1.value }}</div>
          <div class="font_12 col_666 l_h_100_per m_t_8">
            {{ item1.label }}
          </div>
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <div class="h_16"></div>

    <!--  -->
  </div>
</template>

<script>
import { columnAmount } from '@/utils/tools';

import {
  brokerDashboardStayLoanStatisticsAnalysisPost,
  brokerDashboardStayLoanStatisticsPost,
} from '@/api/index';

const defaultFilterForm = () => {
  return {};
};

export default {
  components: {},
  data() {
    return {
      filterForm: { ...defaultFilterForm() },

      statisticList: [],
      analysisList: [],

      //
    };
  },
  computed: {
    //
  },
  mounted() {
    this.init();

    //
  },
  methods: {
    columnAmount,

    // 获取 待放分析
    async getAnalysisList() {
      const list = await brokerDashboardStayLoanStatisticsAnalysisPost();
      this.analysisList = list.map((item, index) => {
        return {
          iconUrl:
            index == 1
              ? 'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/OfLavtUjwALepGyGZAyn.svg'
              : index == 2
              ? 'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/JpKMJXhssoZxHUtGtHIo.svg'
              : 'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/wuBIYXJPpyOPbTPWXZgP.svg',
          values: [
            {
              label: item.title2,
              value: columnAmount(item.count, { fixed: 0 }),
            },
            {
              label: item.title1,
              value: columnAmount((item.amount || 0) / 10000),
            },
          ],
        };
      });

      //
    },

    // 获取 待放款统计
    async getStatisticList() {
      const list = await brokerDashboardStayLoanStatisticsPost();
      this.statisticList = list.map((item, index) => {
        return {
          iconUrl:
            index == 1
              ? 'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/SuaGnWrKtqlOUIhjcNmr.svg'
              : 'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/JkvqvOxYUwifAIhfiXhn.svg',
          values: [
            {
              label: item.title2,
              value: columnAmount(item.count, { fixed: 0 }),
            },
            {
              label: item.title1,
              value: columnAmount((item.amount || 0) / 10000),
            },
          ],
        };
      });

      //
    },

    // 初始化
    async init() {
      this.getStatisticList();
      this.getAnalysisList();

      //
    },

    //
  },
};
</script>
